import React from "react"
import "../../components/my_global.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Row, Col } from "reactstrap"
import stick from "../../images/stick.webp"
import waterRes from "../../images/waterResource.jpg"
import "../../components/prizes.css"
import { StaticImage } from "gatsby-plugin-image"
// images

const WaterResource = () => {
  return (
    <Layout>
      <SEO title={"What We're Reading"} />
      <div className="page-heading reading-bg">
        <Container>
          <Row>
            <Col lg="8" className="mx-auto text-center text-white">
              <h3 className="h3s"> American-Made Challenges</h3>
              <h2 className="h2s">Water Resource Recovery Prize</h2>
              <p
                className="page-hero-subheading"
                style={{ textAlign: "justify" }}
              >
                The U.S. Department of Energy (DOE) Advanced Manufacturing
                Office (AMO) has launched a new prize—the first AMO prize to be
                announced as part of DOE’s{" "}
                <a href="https://www.energy.gov/eere/water-security-grand-challenge">
                  Water Security Grand Challenge
                </a>{" "}
                , whose goal is to advance transformational technology and
                innovation to meet the global need for safe, secure, and
                affordable water. A two-phase competition, the Water Resource
                Recovery prize offers up to $1 million in cash prizes and is
                designed to develop innovative, cutting-edge water treatment
                technology system configurations and business plans that will
                help lower the ultimate cost of treatment by extracting
                additional value from wastewater.
              </p>
              <p style={{ textAlign: "justify" }}>
                <a href="" style={{ color: "white" }}>
                  Learn more about this prize
                </a>
              </p>

              <div className="column is-12">
                <div className="has-text-centered">
                  <a href="https://www.herox.com/WaterResourceRecovery">
                    <button type="button" className="btns">
                      Join the Challenge?
                    </button>
                  </a>
                </div>
                <span className="whitespace"></span>
              </div>
            </Col>
          </Row>
          <StaticImage src="../../images/stick.webp" className="stick" alt="stick" loading="eager"/>
        </Container>
      </div>
      <div className="section_padding">
        <div className="container">
          <h1 className="mac_h1">
            The Water Resource Recovery Prize: Advancing Transformational Water
            Treatment Technologies
          </h1>
          <p className="pcol-fz">
            As part of the American-Made Challenges series, the E-ROBOT Prize
            unites the world’s best-in-class research base with the unparalleled
            entrepreneurial support system of the American-Made Network.
            Consisting of pioneering makerspaces, dozens of energy incubators,
            universities, and 17 U.S. Department of Energy (DOE) national
            laboratories, the Network is primed to create a sweeping portfolio
            of innovations to demonstrate the promise of advanced robotics for
            building envelope retrofits.
          </p>
          <p className="pcol-fz">
            Through this prize competition, DOE is seeking novel, systems-based
            solutions from multidisciplinary teams to implement resource
            recovery at small- to medium-sized water resource recovery
            facilities (WRRFs).
          </p>
          <p className="pcol-fz">
            By presenting cost-effective and innovative facility engineering
            solutions, WRRFs can progress toward the goal of doubling resource
            recovery and accelerate the transition from conventional wastewater
            treatment to a model of resource recovery from municipal wastewater
            across the United States.
          </p>
          <p className="pcol-fz">
            Applications for participation will be evaluated on demonstrated
            achievement of target recovery levels, with additional credit being
            given for innovation and replicability. Beyond the cash prize,
            winners may be recognized by having their plans published on a
            public-facing website to provide potential wastewater treatment
            recovery strategies that other wastewater treatment facilities might
            adopt.
          </p>
          <hr />
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h1 className="mac_h1">Congratulations to our Phase 1 Winners!</h1>
          <hr />
          <p>
            Phase 1 is now closed and offered $50,000 in cash awards to winning
            submissions.
          </p>
          <div>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>
                    entry title{" "}
                    <span class="is-size-7 has-text-weight-normal">
                      (listed alphabetically)
                    </span>
                  </th>
                  <th className="tds">team</th>
                  <th className="tds">location</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26205"
                      class="has-text-bright"
                    >
                      CCR's LTDis Technology, Creating a Solution
                    </a>
                  </td>
                  <td className="tds">Marina Foster</td>
                  <td className="tds">Dallas, TX</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26303"
                      class="has-text-bright"
                    >
                      Electrochemical Recovery: Fertilizer, Fuel, H2O
                    </a>
                  </td>
                  <td className="tds">Lauren Greenlee's Team</td>
                  <td className="tds">Fayetteville, AK</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26646"
                      class="has-text-bright"
                    >
                      Genifuel Not Waste
                    </a>
                  </td>
                  <td className="tds">Genifuel</td>
                  <td className="tds">Salt Lake City, UT</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26654"
                      class="has-text-bright"
                    >
                      Get it Fast, Get it Early: Energy from WW Solids
                    </a>
                  </td>
                  <td className="tds">Sebastien Tilmans's Team</td>
                  <td className="tds">Stanford, CA</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26485"
                      class="has-text-bright"
                    >
                      InSanirator; Shaping the Future of Sanitation
                    </a>
                  </td>
                  <td className="tds">InSanirator</td>
                  <td className="tds">Cambridge, MA</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26628"
                      class="has-text-bright"
                    >
                      Integrated Biodigester Resource Recovery Project
                    </a>
                  </td>
                  <td className="tds">Michael Smith</td>
                  <td className="tds">Seattle, WA</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26689"
                      class="has-text-bright"
                    >
                      Resource Recovery via HTC of Organic Waste
                    </a>
                  </td>{" "}
                  <td className="tds">Dan Spracklin</td>
                  <td className="tds">Spring City, PA</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26688"
                      class="has-text-bright"
                    >
                      Resource Recovery with SCWO
                    </a>
                  </td>
                  <td className="tds">Kobe Nagar</td>
                  <td className="tds">Durham, NC</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26600"
                      class="has-text-bright"
                    >
                      The Road to Energy Neutrality
                    </a>
                  </td>
                  <td className="tds">
                    Kishwaukee Water Reclamation District Team
                  </td>
                  <td className="tds">DeKalb, IL</td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.herox.com/WaterResourceRecovery/round/575/entry/26694"
                      class="has-text-bright"
                    >
                      Sustainable Phosphorus Recovery Technology
                    </a>
                  </td>
                  <td className="tds">Regina Rodriguez's Team</td>
                  <td className="tds">Gainesville, FL</td>
                </tr>
              </tbody>
            </table>
            <div className="has-text-centered">
              <a
                href="https://www.herox.com/WaterResourceRecovery/community"
                target="_blank"
              >
                <button type="button" className="btns">
                  View Phase 1 Winners on HeroXs
                </button>
              </a>
            </div>
          </div>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <div>
            <h1 className="mac_h1">Important Dates</h1>
            <h2> Phase 1</h2>
            <ul className="boxy-bullets gold-bullets ps list-styled">
              <li>
                <b>Phase 1 submissions open:</b> January 29, 2020
              </li>
              <li>
                <b>Phase 1 submissions close: </b> May 28, 2020 (at 5pm ET)
              </li>
              <li>
                <b>Phase 1 notification: </b> June 28, 2020
              </li>

              <li>
                <b>Phase 2 submissions open: </b> August 12, 2020
              </li>
              <li>
                <b>Phase 2 submissions close: </b> August 12, 2021
              </li>
              <li>
                <b>Phase 2 notification:</b> September 9, 2021
              </li>
            </ul>
            <p className="ps">
              Sign up for updates on our{" "}
              <a
                href="https://www.herox.com/WaterResourceRecovery"
                onclick="gtag('event', 'action', {'event_category': 'navigated to herox', 'event_label': 'linked to erobot'})"
              >
                HeroX challenge page
              </a>
              .
            </p>
            <b class="is-size-7">
              All dates are subject to change including contest openings,
              deadlines, and announcements.
            </b>
          </div>
          <hr />
        </div>
        <div className="container">
          <h1 className="mac_h1">Who can participate?</h1>
          <hr />
          <p className="pcol-fz">
            Small- to medium-sized WRRFs (defined by DOE as those facilities
            treating no more than 50 million gallons per day, based on a
            calendar year average), technology developers, resource customers
            (e.g., farmers, electric and gas utilities), academic researchers,
            regulators, business/financial interests, local governments and
            non-profit organizations are all welcome and encouraged to compete.
            However, DOE expects that at least one WRRF will be a part of any
            successful submission.
          </p>
          <p>
            Please review the{" "}
            <a href="../../waterresourse.pdf" target="_blank">
              Submission Requirements
            </a>{" "}
            for more information.
          </p>
        </div>
        <span style={{ padding: "5px" }}></span>
        <div className="container">
          <h3 className="h3ps mac_h1">
            {" "}
            Solutions that help shape the future{" "}
          </h3>
          <StaticImage
            src="../../images/waterResource.jpg"
            className="img-styl"
            alt="water resource"
            loading="lazy"
          />
          <p className="hps">
            Are you a thinker, entrepreneur, facility, or potential partner?
            Anyone with an innovative idea can help promote transformation by
            participating in the American-Made Challenges.
          </p>
          <div style={{ textAlign: "center" }}>
            <a href="https://www.herox.com/WaterResourceRecovery">
              <button type="button" class="btns">
                Ready for a Challenge?
              </button>
            </a>
          </div>
        </div>

        <span style={{ padding: "5px" }}></span>
      </div>
    </Layout>
  )
}

export default WaterResource
